
<template>
  <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout" multi-line tile>
    {{ snackbarText }}

    <template v-slot:action="{ attrs }">
      <v-btn
        :color="snackbarCloseButtonColor"
        text
        v-bind="attrs"
        @click="showSnackbar = false"
      >
        {{ $t("app.actions.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: "snackbar-handler",
  //   data() {return ({
  //       snackbar
  //   })},
  computed: {
    snackbar() {
      return this.$store.getters["notification/snackbar"];
    },
    showSnackbar: {
      get() {
        return this.snackbar.message != null;
      },
      set(val) {
        this.$store.commit("notification/CLEAR_SNACKBAR");
      },
    },
    snackbarText() {
      return this.snackbar.message;
    },
    snackbarTimeout() {
      return this.snackbar.timeout;
    },
    snackbarCloseButtonColor() {
      return this.snackbar.color;
    },
    isSnackbarClosable() {
      return this.snackbar.closable;
    },
  },
};
</script>
