import axios from '#axios';
import Vue from 'vue'
import * as types from '../mutation-types'
import router from '../../router'
import * as routes from '../../router/route-names'
import i18n from '../../i18n';
import isJwt from '/utils/is-jwt'
//import DEFAULT from '../../utils/defaults'


// const DEFAULT_PREFERENCES = {
//     theme: 'light',
//     pagination: DEFAULT.pagination
// }

// export const DEFAULT_USER = {
//     preferences: DEFAULT_PREFERENCES
// }

// export default {
export const state = {
    role: null,
    jwt: null
};
export const mutations = {
    [types.SET_JWT](state, jwt) {
        state.jwt = jwt
        localStorage.setItem('token', jwt)
    },
    // [types.SET_USER](state, user) {
    //     state.user = { ...state.user, ...user, }
    // },
    [types.CLEAR_USER](state) {
        state.jwt = null;
        state.role = null
        localStorage.clear();
    },
    // [types.SET_UI_THEME](state, { theme }) {
    //     if (theme) {
    //         Vuetify.framework.theme.dark = theme == 'dark'
    //         state.user.preferences.theme = theme
    //     }
    // },
    // [types.SET_INVITES_LEFT](state, invitesLeft) {
    //     state.user = { ...state.user, invitesLeft }
    // }
    [types.SET_ROLE](state, role) {
        state.role = role
    }
};
export const actions = {
    async init({ commit, dispatch, getters }) {
        if (getters['token'] == null) {

            //var ok = await dispatch('refreshToken');
            var jwt = localStorage.getItem('token');
            if (jwt && isJwt(jwt)) {
                commit(types.SET_JWT, jwt)
                return dispatch('profile/fetchProfile', {}, { root: true })

            }
            else {
                localStorage.removeItem('token')
            }
        }
        return;
        //dispatch('navigateTo', 'Login', { root: true })
    },
    // fetchProfile({ commit }) {
    //     var xhr = axios.get('profile');
    //     xhr.then(res => {

    //         var user = res.data.data;
    //         commit(types.SET_USER, user)
    //         commit(types.SET_UI_THEME, { theme: user.preferences.theme })
    //     })
    //     return xhr
    // },
    async login({ commit, dispatch }, { email, password }) {
        const params = new URLSearchParams()
        params.append('email', email)
        params.append('password', password)

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        var xhr = axios.post('/auth/login', params, config)

        xhr.then(res => {
            var jwt = res.data.data.token;
            commit(types.SET_JWT, jwt);
            dispatch('profile/fetchProfile', {}, { root: true })
            dispatch('navigateTo', { routeName: routes.INDEX }, { root: true })

        }).catch(e => { })

        return xhr
    },
    refreshToken({ commit, dispatch }) {
        var xhr = axios.get('/auth/refresh');
        xhr.then(res => {
            if (res.status != 200) {
                router.push('Login')
                return false;
            }
            var jwt = res.data.data.token;
            commit(types.SET_JWT, jwt);
            dispatch('profile/fetchProfile', {}, { root: true })
            Vue.$log.debug('Is authenticated and jwt refreshed')
            return true
        })
        xhr.catch(err => {
            router.push('Login')
            return false;
        })
        return xhr;
    },
    logout({ dispatch, commit }) {
        commit(types.CLEAR_USER);
        dispatch('navigateTo', { routeName: routes.LOGIN }, { root: true })
    },
    // updateUserPreferences({ dispatch, commit }, { theme }) {

    // },
    // updateUITheme({ dispatch, commit }, { theme }) {
    //     var userProfile = { preferences: { theme } };
    //     var xhr = axios.patch('profile', userProfile)

    //     xhr.then(res => {
    //         commit(types.SET_UI_THEME, { theme })
    //     })

    //     return xhr;
    // },
    sendPasswordRecoveryEmail({ dispatch, commit }, { email }) {
        var xhr = axios.post('/auth/forgot-password', { email })
        xhr.then(res => {
            commit('notification/SET_SNACKBAR', { message: 'Email sent.' }, { root: true })
        }).catch(err => {
            console.error(err.response)
        })
        return xhr;
    },
    resetPassword({ dispatch, commit }, { email, newPassword, token }) {
        var xhr = axios.post('/auth/reset-password', { email, newPassword, token })

        xhr.then(res => {
            var jwt = res.data.data.token;
            commit(types.SET_JWT, jwt);
            dispatch('profile/fetchProfile', {}, { root: true })
            dispatch('navigateTo', { routeName: routes.INDEX }, { root: true })
        });

        return xhr;
    },
    claimAccount({ dispatch, commit }, { email, password, token, username }) {

        var xhr = axios.post("auth/claim-invite", { email, password, token, username })

        xhr.then(async res => {
            var jwt = res.data.data;
            commit(types.SET_JWT, jwt);
            await dispatch('profile/fetchProfile', {}, { root: true })
            dispatch('navigateTo', { routeName: routes.INDEX }, { root: true })
            commit('notification/SET_SNACKBAR', { message: i18n.t('app.actions.accountActivated') }, { root: true })
        }).catch(e => {
            if (e.response.status == 400) {
                commit('notification/SET_SNACKBAR', { message: e.response.data.message }, { root: true })
            }
        })
    }
};
export const getters = {
    token: state => state.jwt != null ? state.jwt : null,
    isTokenExpired(state, getters) {
        var token = getters['token']
        if (token == null || token == null) {
            return true
        }

        var jwt = atob(token.split('.')[1]);
        return jwt.exp * 1000 <= new Date(Date.now());
    },
    isAuthenticated: state => state.jwt != null,
    //profile: state => state.user,
    //preferences: state => state.user != null ? state.user.preferences != null ? state.user.preferences : DEFAULT_PREFERENCES : DEFAULT_PREFERENCES,
    //uiTheme: state => state.user != null && state.user.preferences != null ? state.user.preferences.theme : 'light',
    role: (state, getters, rootState, rootGetters) => rootGetters['profile/profile'] != null ? rootGetters['profile/profile'].role.name : null,
    userId: (state, getters, rootState, rootGetters) => rootGetters['profile/profile'] != null ? rootGetters['profile/profile'].id : null,
    publicId: (state, getters, rootState, rootGetters) => rootGetters['profile/profile'] != null ? rootGetters['profile/profile'].publicId : null,
}
// }