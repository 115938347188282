import axios from '../../plugins/axios'
import cleanAxios from 'axios'
import i18n from '../../i18n'
import cleanObject from '../../utils/clean-object';
import * as types from '../mutation-types'
import qs from 'qs'

export const state = {
    books: [],
    current: null,
    themes: []
};
export const mutations = {
    [types.SET_BOOKS](state, books) {
        state.books = books;
    },
    [types.SET_CURRENT_BOOK](state, bookId) {
        state.current = state.books.find(x => x.publicId == bookId || x.id == bookId)
    },
    [types.CLEAR_BOOKS](state) {
        state.books = []
    },
    [types.SET_THEMES](state, themes) {
        state.themes = themes
    }
}
export const actions = {
    upsert({ dispatch, commit }, { bookId, title, exerpt, isbn, isActive, edition, publishingDate, files, themes, keywords, authors }) {
        if (bookId != null) {
            return dispatch('updateBook', { bookId, title, exerpt, isbn, isActive, publishingDate, files, keywords, themes, authors, edition });
        }
        else {
            return dispatch("addBook", { title, exerpt, isbn, publishingDate, files, keywords, themes, edition, authors })
        }
    },
    addBook({ dispatch, commit }, { title, edition, exerpt, publishingDate, isbn, files, keywords, themes, authors }) {
        var formData = new FormData();
        for (var file in files) {
            formData.append("files", files[file]);
        }
        // formData.append("title", title);
        // formData.append("exerpt", exerpt)
        // formData.append("publishingDate", publishingDate)
        // formData.append("isbn", isbn)
        formData.append("data", JSON.stringify({ title, exerpt, isbn, publishingDate, edition, themes, keywords, authors }))


        return new Promise((resolve, reject) => {
            axios.post("book", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(res => {
                    resolve(true)
                }).catch(e => {
                    reject(e)
                })
        });
    },
    fetchBookData({ dispatch }, bookId) {
        var xhr = axios.get(`book/${bookId}`);

        return xhr.then(res => {
            return res.data.data
        })
    },
    deactivateBook({ dispatch, commit }, bookId) {
        return new Promise((resolve, reject) => {
            axios.delete(`book/${bookId}`)
                .then(res => {
                    var inactive = res.data.data
                    if (inactive) {
                        commit("notification/SET_SNACKBAR", { message: i18n.t('app.book.actions.deleted') }, { root: true })
                    }

                    resolve(inactive)
                }).catch(() => {
                    reject(null)
                });
        })
    },
    updateBook({ dispatch, commit }, { bookId, isActive, edition, title, exerpt, publishingDate, isbn, files, keywords, themes, authors }) {
        var formData = new FormData();
        for (var file in files) {
            formData.append("files", files[file]);
        }
        // formData.append("title", title);
        // formData.append("exerpt", exerpt)
        // formData.append("publishingDate", publishingDate)
        // formData.append("isbn", isbn)
        formData.append("data", JSON.stringify({ title, exerpt, isbn, publishingDate, edition, isActive, keywords, themes, authors }))
        return new Promise((resolve, reject) => {

            //axios.patch(`book`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            axios.patch(`book/${bookId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((res) => {
                    var data = res.data.data
                    console.log(data)
                    commit("notification/SET_SNACKBAR", { message: i18n.t('app.book.actions.updated') }, { root: true })
                    resolve(data)
                }).catch(e => {
                    reject();
                })
        });
    },
    loadBooks({ dispatch, commit }, { page, itemPerPage, bookTitle, keywords, themes }) {
        //return new Promise((resolve, reject) => {
        var params = cleanObject({ page, itemPerPage, bookTitle, keywords, themes })


        axios.get("book", { params, paramsSerializer: (params) => qs.stringify(params) })
            .then(res => {
                var books = res.data.data
                var pagination = res.data.pagination;

                commit(types.SET_BOOKS, books)
                //resolve({ books, pagination })
            })
        //});
    },
    loadThemes({ commit, getters }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (getters["themes"].length > 1 && !forceLoad)
                resolve()
            else
                axios.get('theme').then(res => {
                    var r = res.data.data
                    commit(types.SET_THEMES, r);

                    resolve();
                }).catch(e => {
                    reject();
                })
        });

    },
    downloadBook({ dispatch, commit }, { bookId, bookName, format }) {
        return new Promise((resolve, reject) => {
            axios.get(`book/${bookId}/download/${format}`, { headers: { 'Content-Type': 'application/octet-stream', }, responseType: 'blob' }).then(res => {
                dispatch("download", {
                    blob: res.data, filename: `${bookName}.${format.toLowerCase()}`
                }, { root: true });
                resolve();
            }).catch(e => {
                if (e.response.status == 410)
                    commit('notification/' + types.SET_SNACKBAR, { message: i18n.t('app.book.errors.bookFileNotFound'), color: 'error' }, { root: true })
                reject()
            })
        });
    },
    searchBookWithExternalAPI({ dispatch, commit }, { bookTitle }) {
        return new Promise((resolve, reject) => {
            if (!(bookTitle || bookTitle.trim())) {
                reject();
            }
            bookTitle = `"${bookTitle.trim()}"`
            var params = new URLSearchParams();
            params.append("q", "intitle:" + bookTitle)
            cleanAxios.get("https://www.googleapis.com/books/v1/volumes", { params })
                .then(res => {
                    var items = res.data.items
                    items = items.map(x => ({
                        kind: x.kind,
                        id: x.id,
                        title: x.volumeInfo.title + ': ' + x.volumeInfo.subtitle,
                        authors: x.volumeInfo.authors,
                        publishedDate: x.volumeInfo.publishedDate,
                        description: x.volumeInfo.description,
                        isbn: x.volumeInfo.industryIdentifiers.find(y => y.type === 'ISBN_13')?.identifier,
                        categories: x.volumeInfo.categories
                    }))
                    // .filter((item, index, self) =>
                    //     index === self.findIndex((t) => (t.volumeInfo.industryIdentifiers.filter(x => x.type === 'ISBN_13')[0].identifier === item.volumeInfo.industryIdentifiers.filter(x => x.type === 'ISBN_13')[0].identifier))
                    // )
                    // console.log(items)
                    if (!items || items.length == 0) {
                        reject()
                    }
                    resolve(items)

                }).catch(e => {
                    alert('Error')
                })
        })
    }
}
export const getters = {
    books: (state) => state.books,
    current: (state) => state.current,
    themes: state => state.themes
}