import axios from '../../plugins/axios'
import * as types from '../mutation-types'
import Vuetify from '../../plugins/vuetify';
import DEFAULT_PREFERENCES from '../../utils/defaults'

export const state = {
    profile: {},
    invitations: null,
    preferences: DEFAULT_PREFERENCES,
    notifications: []
}
export const mutations = {
    [types.SET_USER](state, user) {
        if (user != null && typeof user == 'object') {
            state.profile = { ...user }
            state.preferences = user.preferences,
                state.invitations = user.invitations
        }
    },
    [types.SET_PREFERENCES](state, preferences) {
        if (preferences != null && typeof preferences == 'object')
            state.preferences = preferences
        else state.preferences = DEFAULT_PREFERENCES
    },
    [types.SET_INVITATIONS](state, invitations) {
        if (invitations == null)
            invitations = [];
        else
            state.invitations = invitations;
    },
    [types.CLEAR_USER](state) {
        state.profile = null;
        state.invitations = null;
        state.preferences = null;
    },
    [types.SET_UI_THEME](state, { theme }) {
        if (theme) {
            Vuetify.framework.theme.dark = theme == 'dark'
            state.preferences.theme = theme
        }
    },
    [types.SET_INVITES_LEFT](state, invitesLeft) {
        state.user = { ...state.user, invitesLeft }
    },
    [types.SET_USER_NOTIFICATIONS](state, notifications) {
        state.notifications = notifications
    },
    [types.CLEAR_USER_NOTIFICATIONS](state) {
        state.notifications = []
    },
    [types.CANCEL_INVITE](state, invite) {
        var invites = [].concat(state.invitations)

        var index = invites.findIndex(x => x.token == invite.publicId)
        var temp = { ...invites[index], token: invite.publicId, statusId: invite.status.id, status: invite.status.name }
        invites.splice(index, 1, temp);
    }
}
export const actions = {
    init({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            //dispatch('loadNotifications', false).finally(() => {
            resolve()
            //})
        })
    },
    fetchProfile({ commit }) {
        var xhr = axios.get('profile');
        xhr.then(res => {

            var user = res.data.data;
            commit(types.SET_USER, user)
            commit(types.SET_PREFERENCES, user.preferences)
            commit('auth/' + types.SET_ROLE, user.role, { root: true })
            //commit(types.SET_UI_THEME, { theme: preferences.theme })
        })
        return xhr
    },
    fetchInvitations({ dispatch, commit }) {


    },
    updateUITheme({ dispatch, commit }, { theme }) {
        var userProfile = { preferences: { theme } };
        var xhr = axios.patch('profile', userProfile)

        xhr.then(res => {
            commit(types.SET_UI_THEME, { theme })
        })

        return xhr;
    },
    updateUserPreferences({ dispatch, commit }, { theme }) {

    },
    loadNotifications({ dispatch, commit }, all) {
        return new Promise((resolve, reject) => {
            var params = new URLSearchParams();
            params.append('all', all ? true : false)
            axios.get('profile/notifications', { params }).then(res => {
                var data = res.data.data
                commit(types.SET_USER_NOTIFICATIONS, data);
                resolve();
            }).catch(x => {
                reject()
            })
        })
    },
    markNotificationAsRead({ commit, dispatch }, ids) {
        return new Promise((resolve, reject) => {
            axios.patch('profile/notifications', { notificationIds: ids }).then(res => {
                var data = res.data.data
                commit(types.SET_USER_NOTIFICATIONS, data)
                resolve();
            }).catch(x => {
                reject()
            })
        })
    }
}
export const getters = {
    profile: state => state.profile,
    preferences: (state) => state.preferences,
    uiTheme: (state, getters) => getters['preferences'].theme,
    userId: state => state.user != null ? state.user.id : null,
    publicId: state => state.user != null ? state.user.publicId : null,
    notifications: state => state.notifications,
    unreadNotifications: state => state.notifications.filter(x => x.readAt == null)
}