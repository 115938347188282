import Vue from 'vue';
import * as Routes from './route-names';
import VueRouter from 'vue-router'
import store from '/store'

const LoginLayout = () => import('../views/Login/LoginLayout')
const LoginPage = () => import('../views/Login/Login.vue')
const ForgotPasswordPage = () => import('../views/Login/ForgotPassword')
const ResetPasswordPage = () => import('../views/Login/ResetPassword')
const ClaimAccountPage = () => import('/views/Login/ClaimAccount')

const Layout = () => import('../views/Layout')
//import Layout from '../views/Layout';
const LibraryPage = () => import('../views/Library')
const LibraryDrawer = () => import('../views/Library/LibraryDrawer')

const ProfilePage = () => import('../views/Profile/Profile')
const ProfileInvitationsPage = () => import('../views/Profile/Invitations')
const ProfileSidebar = () => import('../components/Profile/Sidebar')
const ContributionsPage = () => import('../views/Profile/Contributions')

const BookPage = () => import('../views/Library/Book')

const UserList = () => import('../views/User/UserList')
const UserProfilePage = () => import('../views/User/UserProfile')

const KeywordConfigurationPage = () => import('../views/Configuration/KeywordConfiguration')
const ControlPanelPage = () => import('../views/Configuration/ControlPanel')

import auth from './middlewares/auth-middleware'
import fetchNotifications from './middlewares/notification-fetch-middleware'
import EmptyLayout from '../views/Empty'
Vue.use(VueRouter);

const routes = [
    {
        path: "",
        component: Layout,
        children: [
            {
                path: '',
                name: Routes.LIBRARY,
                components: {
                    default: LibraryPage
                },
                meta: { sidebar: false }
            },
            {
                path: '/control-panel',
                name: Routes.CONFIGURATION_PAGE,
                component: ControlPanelPage
            },
            {
                path: '/profile',
                components: {
                    default: EmptyLayout,
                    sidebar: ProfileSidebar
                },
                children: [
                    {
                        path: '/invitations',
                        name: Routes.PROFILE_INVITATIONS,
                        components: {
                            default: ProfileInvitationsPage
                        }
                    },
                    {
                        path: '/my-contributions',
                        name: Routes.PROFILE_CONTRIBUTIONS,
                        components: {
                            default: ContributionsPage
                        }
                    },
                    {
                        path: '',
                        name: Routes.PROFILE,
                        components: {
                            default: ProfilePage,
                            //sidebar: ProfileSidebar
                        },
                        //meta: { sidebar: true }
                    }
                ],
                meta: {
                    sidebar: true
                },
                props: {
                    default: true,
                    userId: store.getters['profile/userId']
                }
            },
            {
                path: "users",
                components: {
                    default: EmptyLayout,
                },
                children: [
                    {
                        path: 'list',
                        name: Routes.USER_LIST,
                        components: {
                            default: UserList
                        }
                    },
                    {
                        path: ":userId",
                        name: Routes.USER_PROFILE,
                        components: {
                            default: UserProfilePage
                        },
                        props: { default: true, userId: route => ({ search: route.query.q }) }
                    }
                ]
            },
            {
                path: "book/:bookId",
                name: Routes.BOOK,
                components: {
                    default: BookPage
                },
                //params: true
                props:
                {
                    default: true,
                    bookId: route => ({ search: route.query.q })
                }
            }
        ],
        meta: { middleware: [auth, fetchNotifications], requiresAuth: true }
    },
    {
        path: '',
        component: LoginLayout,
        children: [
            {

                path: "/login",
                name: Routes.LOGIN,
                components: {
                    default: LoginPage
                }
            },
            {

                path: "/forgot-password",
                name: Routes.FORGOT_PASSWORD,
                components: {
                    default: ForgotPasswordPage
                }
            },
            {

                path: "/reset-password/:enc",
                name: Routes.RESET_PASSWORD,
                components: {
                    default: ResetPasswordPage
                },
                props: {
                    default: true,
                    navigation: route => ({ search: route.query.q })
                }
            },
            {
                path: "/claim-account/:token",
                name: Routes.CLAIM_ACCOUNT,
                components: { default: ClaimAccountPage },
                props: {
                    default: true,
                    token: route => ({ search: route.query.q })
                }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {

    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) {
        return context.next;
    }

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    store.dispatch('init').then(() => {
        var middlewares = to.matched
            .filter(x => Object.keys(x.meta).some(y => y == 'middleware'))
            .map(x => x.meta.middleware);
        middlewares = middlewares.flatMap(x => x);
        middlewares = middlewares.filter((value, index, self) => self.indexOf(value) === index);
        if (middlewares.length) {

            // const middleware =Array.isArray(to.meta.middleware)
            //     ? to.meta.middleware
            //     : [to.meta.middleware];

            const context = {
                from,
                next,
                router,
                to,
            };
            const nextMiddleware = nextFactory(context, middlewares, 1);

            return middlewares[0]({ ...context, next: nextMiddleware });
        }

        return next();
    })
});

export default router