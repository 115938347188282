import axios from '#axios'
import * as types from '../mutation-types'

export const state = {
    roles: [],
    keywords: []
};
export const mutations = {
    [types.SET_ROLES](state, roles) {
        if (roles == null)
            state.roles = []
        else
            state.roles = roles
    },
    [types.SET_KEYWORDS](state, keywords) {
        if (keywords == null) {
            state.keywords = []
        }
        else state.keywords = keywords
    }
}
export const actions = {
    loadAll({ dispatch }, { forceLoad }) {
        dispatch('loadRoles', { forceLoad });
    },
    loadKeywords({ commit }, { withBookCount }) {
        var params = new URLSearchParams()
        params.append('withBookCount', withBookCount ? true : false)
        return new Promise((resolve, reject) => {
            axios.get('keyword', { params }).then(res => {
                var keywords = res.data.data
                commit(types.SET_KEYWORDS, keywords)
                resolve()
            }).catch(e => reject(e))
        });
    },
    loadRoles({ dispatch, commit, getters }, { forceLoad }) {
        return new Promise((resolve, reject) => {
            if (getters["roles"].length > 1 && !forceLoad)
                resolve()
            else
                axios.get('role').then(res => {
                    var r = res.data.data
                    commit(types.SET_ROLES, r);
                    resolve();
                }).catch(e => {
                    reject();
                })
        });
    },
    saveKeywords({ commit, dispatch }, keywords) {

        return new Promise((resolve, reject) => {
            axios.patch('keyword', keywords).then(x => {
                commit('notification/SET_SNACKBAR', { message: "Keywords state has been saved" }, { root: true })
                resolve()
            }).catch(() => {
                commit('notification/SET_SNACKBAR', { message: "Keywords state could not be saved" }, { root: true })
                reject()
            })
        })
    }

}
export const getters = {
    roles: (state) => state.roles,
    keywords: (state) => state.keywords
}