import Vue from 'vue'
//import axios from "axios";
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import i18n from './i18n';
import momentTimezone from 'moment-timezone'
import store from './store'
import './assets/css/global.css'


import './plugins'

export const bus = new Vue();

Vue.config.productionTip = process.env.NODE_ENV == "developpement";
Vue.use(require('vue-moment'), { momentTimezone });

// export default axios.create({
//     timeout: 5000
// });

// axios.interceptors.request.use(
// function (config) {
//     const token = localStorage.getItem("token");
//     if (token != null) {
//         config.headers.Authorization =
//             "Bearer " + localStorage.getItem("token");
//     }

//     return config;
// },
// function (err) {
//     return Promise.reject(err);
// }
// );

// var init = function () {
//     return store.dispatch('init')
// }
//init()

new Vue({
    vuetify,
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')

Vue.component("book-form", () => import('./components/Library/BookForm'))
Vue.component("user-dialog", () => import('./components/User/UserDialog'))
Vue.component("dialog-search-book", () => import('./components/Library/SearchDialog'))
