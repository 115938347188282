import axios from '../../plugins/axios'
import * as types from '../mutation-types'

export const state = { authors: [] };
export const mutations = {
    [types.SET_AUTHORS_LIST](state, authors) {
        if (authors == null) {
            authors = []
        }
        state.authors = authors;
    }
}
export const actions = {
    loadAll({ commit }, { forceLoad }) {
        if (forceLoad == null) {
            forceLoad = true;
        }

        return new Promise((resolve, reject) => {
            axios.get('author')
                .then(res => {
                    var authors = res.data.data;
                    commit(types.SET_AUTHORS_LIST, authors)
                    resolve()
                }).catch(e => {
                    reject()
                })
        });
    }
}
export const getters = {
    authors: state => state.authors
}