import * as types from '../mutation-types'
// import * as routes from '../../router/route-names'
import i18n from '../../i18n'
import { assignIfUndefined } from "../../utils/object";


const DEFAULT_SNACKBAR = {
    message: null,
    timeout: 3000,
    color: "primary",
    closable: true
}

const DEFAULT_DIALOG = {
    width: "25%",
    persistent: false,
    fullscreen: false,
    transitionType: "scale-transition"
}

export const state = {
    snackbar: DEFAULT_SNACKBAR,
    dialogs: [],
    notificationDrawerState: false
};
export const getters = {
    snackbar: state => state.snackbar,
    dialogs: state => state.dialogs,
    isNotificationDrawerOpen: state => state.notificationDrawerState
};
export const mutations = {
    [types.SET_SNACKBAR](state, { message, color, timeout }) {
        state.snackbar = Object.assign(DEFAULT_SNACKBAR, { message, color, timeout })
    },
    [types.CLEAR_SNACKBAR](state) {
        state.snackbar.message = null
        clearTimeout(state.snackbar.timeout)

    },
    [types.ADD_DIALOG](state, { componentName, parameter, width, persistent, fullscreen, transitionType, id, title, onClose }) {
        var dialog = assignIfUndefined({
            component: componentName,
            parameter: parameter,
            width: width,
            maxwidth: width,
            persistent,
            fullscreen,
            transitionType,
            id,
            title,
            onClose
        }, DEFAULT_DIALOG)
        state.dialogs.push(dialog);
    },
    [types.UPDATE_DIALOG](state, { id, parameter, width, persistent, fullscreen, transitionType, title, onClose }) {
        if (id == null) return;
        var dialogIndex = state.dialogs.findIndex(x => x.id == id)
        if (dialogIndex >= 0) {
            var original = Object.assign({}, state.dialogs[dialogIndex]);
            var nDialog = assignIfUndefined({ parameter, width, persistent, fullscreen, transitionType, title, onClose }, original)
            state.dialogs.splice(dialogIndex, 1, nDialog)
        }
    },
    [types.HIDE_DIALOG](state, id) {
        var deleted
        if (id != null) {
            var index = state.dialogs.findIndex(x => x.id == id)
            deleted = state.dialogs.splice(index, 1);
        }
        else
            deleted = state.dialogs.splice(state.dialogs.length - 1)

        deleted = deleted[0]
        if (typeof deleted.onClose == 'function') {
            deleted.onClose()
        }
    },
    [types.TOGGLE_NOTIFICATION_DRAWER](state, { drawerState }) {
        //console.log(drawerState)
        if (drawerState != null) {
            state.notificationDrawerState = drawerState;
        }
        else {
            state.notificationDrawerState = !state.notificationDrawerState
        }
    }
};
export const actions = {
    addDialog({ commit }, { componentName, parameter, width, persistent, fullscreen, transitionType, title, onClose }) {
        return new Promise((resolve, reject) => {
            var id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
            commit(types.ADD_DIALOG, { componentName, parameter, width, persistent, fullscreen, transitionType, id, title, onClose })

            resolve(id)
        })
    },
    // showGlobalSearch({ commit, dispatch }) {
    //     return dispatch('addDialog', { componentName: 'dialog-global-search', persistent: true, title: i18n.t('modal.globalSearch.title'), fullscreen: true })
    // }
    showBookForm({ dispatch }, { parameter }) {
        return dispatch('addDialog', { componentName: 'book-form', parameter, persistent: true, fullscreen: true })
    }
};
