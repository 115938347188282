<template>
  <v-dialog
    v-if="currentDialog != null"
    v-model="showDialog"
    :width="currentDialog.width"
    :max-width="currentDialog.width"
    scrollable
    :persistent="currentDialog.persistent"
    :transition="currentDialog.transitionType"
    :fullscreen="currentDialog.fullscreen"
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="11">
            <h4 class="mx-6" v-if="currentDialog.title">
              {{ currentDialog.title }}
            </h4>
          </v-col>
          <v-col cols="1">
            <v-btn
              class="float-right"
              icon
              small
              dark
              color="grey"
              @click="showDialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-col>
          <component
            :is="currentDialog.component"
            :parameter="currentDialog.parameter"
            :config="currentDialogConfig"
          />
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import * as types from "../../store/mutation-types";
export default {
  name: "dialog-handler",
  computed: {
    dialogs() {
      return this.$store.getters["notification/dialogs"];
    },
    currentDialog() {
      var m =
        this.dialogs.length > 0 ? this.dialogs[this.dialogs.length - 1] : null;
      return m;
    },
    showDialog: {
      get() {
        return this.currentDialog != null;
      },
      set(v) {
        // if (v == true)
        //   this.$store.commit("notification/" + types.SHOW_MODAL, null);
        //else
        this.$store.commit("notification/" + types.HIDE_DIALOG, null);
      },
    },
    currentDialogConfig() {
      var current = this.currentDialog;

      if (current) {
        var conf = { ...current };
        return conf;
      }

      return {};
    },
  },
};
</script>