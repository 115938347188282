

export const LOGIN = "Login"
export const FORGOT_PASSWORD = "Forgot Password"
export const RESET_PASSWORD = "Reset Password"
export const PROFILE = "Profile"
export const LIBRARY = "Library"
export const PROFILE_INVITATIONS = "My Invites"
export const PROFILE_CONTRIBUTIONS = "My contributions"
export const CLAIM_ACCOUNT = "Claim account"

export const BOOK = "Book"

export const AUTHOR = "Author"

export const USER_LIST = "User List"
export const USER_PROFILE = "User Profile"

export const CONFIGURATION_PAGE = "Configuration"

export const INDEX = LIBRARY