import * as routes from '../route-names'
import store from '../../store'

export default function auth({ to, next, router }) {
    var requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    if (!requiresAuth) {
        return next()
    }
    else {
        if (!store.getters['auth/isAuthenticated'] || store.getters['auth/isTokenExpired']) {
            return store.dispatch('auth/logout')
            //return store.dispatch('navigateTo', { routeName: routes.LOGIN })
        }
        return next();
    }
}