import axios from '#axios'
import i18n from '../../i18n'
import * as types from '../mutation-types'
import DEFAULT_PREFERENCES from '../../utils/defaults'

export const state = {
    users: [],
    selectedUser: null,
    pagination: DEFAULT_PREFERENCES.pagination
}
export const mutations = {
    [types.SET_SELECTED_USER](state, user) {
        if (typeof user == 'number' || typeof user == 'string') {
            state.selectedUser = state.users.find(x => x.publicId == user || x.id == user)
        }
        else
            state.selectedUser = user
    },
    [types.ADD_USER](state, user) {
        if (state.users.length == 0) {
            state.users = [].concat([user]);
        }
        else {
            var index = state.users.findIndex(x => x.id == user.id || x.publicId == user.publicId)
            state.users.splice(index, 1, user);
        }
    },
    [types.SET_USERS](state, users) {
        state.users = users;
    },
    [types.CLEAR_SELECTED_USER](state) {
        state.selectedUser = null;
    },
    [types.CLEAR_USERS](state) {
        state.users = []
    },
    [types.SET_PAGINATION](state, pagination) {
        state.pagination = { ...pagination }
    },
    [types.CLEAR_PAGINATION](state, pagination) {
        state.pagination = {}
    }
}
export const actions = {
    invite({ dispatch, commit }, { friendEmail }) {
        var xhr = axios.post("user/invite", { friendEmail })

        xhr.then(res => {
            var emailSent = res.data.data.emailSent
            var message = emailSent ? i18n.t('app.actions.emailSent') : i18n.t('app.actions.emailNotSent');
            commit('notification/SET_SNACKBAR', { message }, { root: true })
            commit('profile/' + types.SET_INVITES_LEFT, res.data.data.invitesLeft, { root: true })
            dispatch('fetchMemberInvitationsList', null);
        }).catch(e => console.error(e));

        return xhr;
    },
    fetchMemberInvitationsList({ rootGetters, dispatch, commit }, memberId) {
        if (memberId == null)
            memberId = rootGetters['profile/profile'].id;
        var xhr = axios.get(`user/${memberId}/invitations`)

        return xhr.then(res => {
            var invites = res.data.data.invitations;
            return invites
        }).catch((e) => {
            console.error(e)
        })
    },
    // Currently only for current user
    cancelInvitation({ dispatch, commit }, token) {
        var xhr = axios.post('/invitation/cancel', { invitationToken: token })

        return xhr.then(res => {
            var inviteLeft = res.data.data.invitesLeft
            var invitationToken = res.data.data.invite

            commit('notification/' + types.SET_SNACKBAR, { message: i18n.t('app.actions.invitationCanceled') }, { root: true })
            // TO be modified if admin can cancel invites
            commit('profile/' + types.SET_INVITES_LEFT, inviteLeft, { root: true })
            commit('profile/' + types.CANCEL_INVITE, invitationToken, { root: true })

        }).catch(err => {
            console.error(err)
            commit('notification/' + types.SET_SNACKBAR, { message: i18n.t('app.actions.invitationNotCanceled') }, { root: true })
        })
    },
    isUserNameTaken({ dispatch }, username) {
        var xhr = axios.get("user/is-username-taken", { params: { username } });
        return xhr.then(res => {
            var isTaken = res.data.data
            return isTaken;
        }).catch(e => console.log(e));
    },
    fetchInvitationStatus({ dispatch }, { token }) {
        var xhr = axios.get("auth/invite-status", { params: { token } })

        return xhr.then(res => {
            return res.data.data
        })
    },
    contributedBooks({ dispatch }, userId) {
        var xhr = axios.get(`user/${userId}/contributed-books`)

        return xhr.then(res => {
            return res.data.data
        })
    },
    loadUsers({ dispatch, commit }, { page, itemsPerPage, isActive }) {
        var params = new URLSearchParams([["page", page], ["itemsPerPage", itemsPerPage], ["isActive", isActive == null ? '' : isActive]])
        axios.get('user', { params })
            .then(res => {
                commit(types.SET_USERS, res.data.data)
                commit(types.SET_PAGINATION, res.data.pagination)
            })
            .catch(e => {
                commit("notification/SET_SNACKBAR", { message: i18n.t('app.actions.cannotLoadUser'), color: "red" }, { root: true })
            })
    },
    updateUser({ dispatch, commit }, { id, publicId, isActive, role }) {
        return new Promise((resolve, reject) => {
            axios.patch(`user/${id == null ? publicId : id}`, { isActive, roleId: role.id }).then(res => {
                var user = res.data.data;
                console.log(user)
                commit(types.ADD_USER, user)
                commit('notification/SET_SNACKBAR', { message: i18n.t('page.userList.notifications.userUpdated') }, { root: true })
                resolve()
            }).catch(e => { reject(e) })
        });
    },
    fetchUser({ dispatch, commit }, userId) {
        return new Promise((resolve, reject) => {
            axios.get(`user/${userId}?withBooks=true&withInvitations=true`).then(res => {
                var user = res.data.data
                commit(types.SET_SELECTED_USER, user);
                resolve();
            }).catch(e => {
                console.warn('NYI: Error when not found')
                reject()
            })
        })
    }
}
export const getters = {
    users: (state) => state.users,
    current: (state) => state.selectedUser,
    pagination: (state, getters, rootState, rootGetters) => {
        // if (state.pagination != null && Object.keys(state.pagination).length != 0) {
        return state.pagination
        // }
        // console.log('no paging')
        // var d = rootGetters['profile/preferences'].pagination;
        // console.log(d)
        // return d
    }
}