<template>
  <v-app
    class="gray lighten-3 mb-6"
    :style="{ background: $vuetify.theme.themes[theme].background }"
    @contextmenu="hideContext($event)"
  >
    <router-view></router-view>
    <snackbar-handler></snackbar-handler>
    <dialog-handler></dialog-handler>
    <v-overlay :value="isLoading">
      <intersecting-circles-spinner
        :animation-duration="1200"
        :size="70"
        color="#ff1d5e"
    /></v-overlay>
  </v-app>
</template>

<script>
import "./assets/css/global.css";
import SnackbarHandler from "./components/Layout/SnackbarHandler";
import DialogHandler from "./components/Layout/DialogHandler.vue";
import { IntersectingCirclesSpinner } from "epic-spinners";
export default {
  name: "home",
  components: { SnackbarHandler, DialogHandler, IntersectingCirclesSpinner },
  data() {
    return {
      refCount: 0,
      bgColor: {
        backgroundColor: "gray",
      },
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    hideContext(e) {
      if (process.env.NODE_ENV != "developpement") e.preventDefault();
    },
    setLoading(isLoading) {
      if (isLoading) {
        this.refCount++;
      } else if (this.refCount > 0) {
        this.refCount--;
      }
    },
  },
};
</script>
<style>
@import "./assets/css/global.css";
</style>