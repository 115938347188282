//#regions All
export const SET_LOADING = "SET_LOADING"
export const SET_PAGINATION = "SET_PAGINATION"
export const CLEAR_PAGINATION = "CLEAR_PAGINATION"
//#endregion

//#region Auth
export const SET_JWT = "SET_JWT"
export const SET_USER = "SET_USER"
export const CLEAR_USER = "CLEAR_USER"
export const SET_UI_THEME = "SET_UI_THEME"
export const SET_INVITES_LEFT = "SET_INVITES_LEFT"
//#endregion

export const SET_PREFERENCES = 'SET_PREFERENCES'
export const SET_INVITATIONS = 'SET_INVITATIONS'

//#region Notification
export const SET_SNACKBAR = "SET_SNACKBAR"
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR"
export const ADD_DIALOG = "SHOW_MODAL"
export const HIDE_DIALOG = "HIDE_MODAL"
export const UPDATE_DIALOG = "UPDATE_DIALOG"
//#endregion

//#region User
export const ADD_USER = "ADD_USER"
export const SET_USERS = "SET_USERS"
export const SET_SELECTED_USER = "SET_SELECTED_USER"
export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER"
export const CLEAR_USERS = "CLEAR_USERS"
//#endregion

//#region Books
export const SET_BOOKS = "SET_BOOKS"
export const SET_CURRENT_BOOK = "SET_CURRENT_BOOK"
export const CLEAR_BOOKS = "CLEAR_BOOKS"
export const SET_THEMES = "SET_THEMES"
//#endregion

export const SET_ROLES = "Set roles list"
export const SET_ROLE = "Set user role"
export const SET_KEYWORDS = "Set Keywords"
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS"
export const CLEAR_USER_NOTIFICATIONS = "CLEAR_USER_NOTIFICATIONS"

export const TOGGLE_NOTIFICATION_DRAWER = "TOGGLE_NOTIFICATION_DRAWER"
export const SET_AUTHORS_LIST = "SET_AUTHORS_LIST"
export const CANCEL_INVITE = "CANCEL_INVITE"