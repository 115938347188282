import Vue from 'vue';
import VuetifyLib from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);


VuetifyLib.config.silent = process.env.NODE_ENV != "developpement"

export default new Vuetify({

    theme: {
        dark: false,
        options: { customProperties: true },
        themes: {
            light: {
                primary: "#62A2BF",
                secondary: '#62A2BF',

                //secondary: '#1e88e5',
                accent: "#316278",
                error: '#BF6365',
                info: '#6365BF',
                success: '#74BF63',
                warning: '#BF9F63',
                background: '#fcfcfc',
            },
            dark: {
                primary: '#3066be',//colors.blue.darken3,
                background: '#404E5C'//'#4a4a4a'
            },
        }
    }
});