import axios from 'axios'
import store from '../store'
import * as types from '/store/mutation-types'
//import router from '~/router'

const instance = axios.create();

instance.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
//axios.defaults.withCredentials = false;
//axios.defaults.headers = { ...axios.defaults.headers }



// Request interceptor
instance.interceptors.request.use(request => {
    const token = store.getters['auth/token']
    if (token) {
        request.headers.common.Authorization = `Bearer ${token}`
    }

    // const locale = store.getters['lang/locale']
    // if (locale) {
    //     request.headers.common['Accept-Language'] = locale
    // }

    // request.headers['X-Socket-Id'] = Echo.socketId()

    return request
})

instance.interceptors.request.use((config) => {
    store.commit(types.SET_LOADING, true)
    return config;
}, (error) => {
    store.commit(types.SET_LOADING, false)
    return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
    store.commit(types.SET_LOADING, false)
    return response;
}, (error) => {
    store.commit(types.SET_LOADING, false)
    return Promise.reject(error);
});

export default instance;