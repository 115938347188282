import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import * as types from './mutation-types'

Vue.use(Vuex);

// export default new Vuex.Store({
//     modules: {}
//     // state: {
//     //     user: null
//     // },
//     // mutations: {},
//     // actions: {},
//     // //plugins: {},
//     // getters: {}
// });

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/)

const modules = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((modules, [name, module]) => {
        if (module.namespaced === undefined) {
            module.namespaced = true
        }

        return { ...modules, [name]: module }
    }, {})

export default new Vuex.Store({
    modules,
    state: {
        isLoading: false
    },
    mutations: {
        [types.SET_LOADING](state, isLoading) {
            state.isLoading = isLoading;
        }
    },
    actions: {
        init({ dispatch }) {
            return Promise.all([dispatch('auth/init'), dispatch('profile/init')])

        },
        navigateTo(context, { routeName, params }) {
            return router.push({ name: routeName, params }).catch(e => { });
        },
        download(context, { blob, filename, applicationType }) {
            blob = new Blob([blob], { type: 'application/octet-stream' });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob)
            link.download = filename;
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            //URL.revokeObjectURL(link.href);
        }
    },
    getters: {
        isLoading: state => state.isLoading
    }
})