
/**
 * @type {String} jwt
 */
export default (jwt) => {
    if (jwt == null || typeof jwt !== 'string') return false;
    var parts = jwt.split('.');

    if (parts.length != 3) {
        return false;
    }

    // TODO: Some work to validate things here
    return true;
}