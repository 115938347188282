/**
 * Cleans an object of all null or undefined values. Creates a copy of the object and modify this copy.
 * @param {object} obj the object that needs cleaning
 * @returns {object} the clean object instance
 */
export default (obj) => {
    var ref = Object.assign({}, obj)
    for (var propName in ref) {
        if (ref[propName] === null || ref[propName] === undefined) {
            delete ref[propName];
        }
    }
    return ref;
}